.ant-btn-primary {
    color: #fff;
    background-color: #78BBDB;
    border-color: #78BBDB;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:hover{
    color: #fff;
    background-color: rgb(0, 120, 176);
    border-color: rgb(0, 120, 176);
}

.ant-btn-primary.ant-btn:focus {
    color: #fff;
    background-color: #78BBDB;
    border-color: #78BBDB;
}
