

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu .ant-menu-item-selected {
  background-color: #78BBDB;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background:  #E0E0E0;
  transition: all 0.2s;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #E0E0E0;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected  {
  color: #fff;
}
