.ant-tag {
 
    display: inline-block;
    height: auto;
    margin-right: 15px;
    padding: 5 10px;
    font-size: 16px;
    line-height: 25px;
    white-space: nowrap;
    border-radius: 4px;

}